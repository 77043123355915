import { useEffect, useState, useRef } from "react";

const Banner = ({ direction = 'ltr' }) => {
  const ImgRef = useRef(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [translation, setTranslation] = useState(0);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  const visibleImagesCount = 4; // Number of images visible at a time

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.cannula-ms.com/places'); // Replace with your actual API endpoint
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const imgUrls = data.filter(item => item.img).map(item => item.img);
        setImages(imgUrls);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();

    const handleResize = () => {
      if (ImgRef.current) {
        setImgWidth(ImgRef.current.offsetWidth);
      }
    };

    handleResize(); // Set initial width
    window.addEventListener('resize', handleResize);

    const interval = setInterval(() => {
      setTranslation(prevTranslation => {
        const step = imgWidth / visibleImagesCount*2;
        const newTranslation = direction === 'ltr' ? prevTranslation - step : prevTranslation + step;
        const maxTranslation = -imgWidth * images.length;
        const minTranslation = imgWidth * images.length;
        if (direction === 'ltr' && newTranslation <= maxTranslation) {
          return 0;
        } else if (direction === 'rtl' && newTranslation >= minTranslation) {
          return 0;
        }
        return newTranslation;
      });
    }, 2000); // Adjust the timing as needed

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, [imgWidth, images.length, direction]);

  useEffect(() => {
    if (ImgRef.current) {
      setImgWidth(ImgRef.current.offsetWidth);
    }
  }, [images]);

  return (
    <section className="section-banner" id="sponsor">
      <div className="banner-container" style={{ direction }}>
        <div
          className="banner-track"
          style={{
            transform: `translateX(${translation}px)`,
            transition: 'transform 1s ease-out',
            whiteSpace: 'nowrap'
          }}
        >
          {error ? (
            <div className="error-message">Error: {error}</div>
          ) : (
            images.concat(images).map((img, index) => ( // Duplicate the images
              <div className="banner-sponsor" key={index} style={{ display: 'inline-block' }}>
                <img src={img} alt={`sponsor-${index}`} ref={index === 0 ? ImgRef : null} />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
